import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  max_value,
  min_value,
  double,
  alpha_dash,
  max,
  min,
} from "vee-validate/dist/rules";
import PhoneNumber from "awesome-phonenumber";
import { DateTime } from "luxon";

// Some examples taken from: vee-validate/dist/vee-validate.full.esm.js
extend("email", {
  ...email,
  message: "The {_field_} field must be a valid email",
});
extend("required", { ...required, message: "The {_field_} field is required" });
extend("confirmed", {
  ...confirmed,
  message: "The {_field_} field confirmation does not match",
});
extend("phone", {
  message(fieldName) {
    return `${fieldName} is not a valid phone number`;
  },
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(value);
      resolve({ valid: phone.isValid() });
    });
  },
});
extend("url", {
  validate(value: string | null | undefined): boolean {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }
    return false;
  },
  message: "The {_field_} must be a valid URL",
});
extend("sql_date", {
  validate: (value) => {
    return DateTime.fromSQL(value).isValid;
  },
  message: "The {_field_} must be a valid SQL date (YYYY-MM-DD HH:MM:SS)",
});
extend("max_value", {
  ...max_value,
  message: "The {_field_} must be less than {max}",
});
extend("min_value", {
  ...min_value,
  message: "The {_field_} must be greater than {min}.",
});
extend("sql_date_after", {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  validate: (value, values: Record<string, any>) => {
    const dateTimes = values.map((value: string) => DateTime.fromSQL(value));
    const latest = dateTimes.sort((a, b) => b.diff(DateTime.fromSQL(a)))[0];
    return (
      DateTime.fromSQL(value) > latest ||
      `The {_field_} must be after ${latest.toSQL({ includeOffset: false })}`
    );
  },
});
extend("sql_date_before", {
  validate: (value, values: Record<string, any>) => {
    const dateTimes = values.map((value: string) => DateTime.fromSQL(value));
    const earliest = dateTimes.sort((a, b) => a.diff(DateTime.fromSQL(b)))[0];
    return (
      DateTime.fromSQL(value) < earliest ||
      `The {_field_} must be before ${earliest.toSQL({ includeOffset: false })}`
    );
  },
});
extend("not_equal", {
  params: ["otherValue"],
  validate: (value, { otherValue }: Record<string, never>) => {
    return value !== otherValue;
  },
  message: "The {_field_} must not equal {otherValue}",
});
extend("double", { ...double });
extend("less_than", {
  validate: (value, values: Record<string, any>) => {
    const maximum = values.sort((a, b) => a - b)[0];
    return value < maximum || `The {_field_} must be less than ${maximum}`;
  },
});
extend("greater_than", {
  validate: (value, values: Record<string, any>) => {
    const minimum = values.sort((a, b) => b - a)[0];
    return value > minimum || `The {_field_} must be greater than ${minimum}`;
  },
});
extend("no_trailing_slash", {
  validate: (value) => {
    return !value.endsWith("/") || "The {_field_} cannot end with a slash";
  },
});
extend("alpha_dash", {
  ...alpha_dash,
  message:
    "The {_field_} must only contain alphabetic, numbers, dashes, and underscores",
});
extend("google_cloud_storage_path", {
  validate: (value: string) => {
    return (
      (value.startsWith("gs://") &&
        !value.includes("\n") &&
        !value.includes("\r")) ||
      "The {_field_} must be a valid Google Cloud Storage path"
    );
  },
});
extend("valid_edge_job_destination", {
  validate: (value: string) => {
    return (
      ((value.startsWith("gs://") || value.startsWith("t15-assuresrv://")) &&
        !value.includes("\n") &&
        !value.includes("\r")) ||
      "The {_field_} must be either a google storage URI, or t15-assuresrv URI"
    );
  },
});
extend("no_hash_character", {
  validate: (value: string) => {
    return (
      !value.includes("#") ||
      "The {_field_} must not contain a hash character (#)"
    );
  },
});
extend("no_gcp_wildcards", {
  validate: (value: string) => {
    return (
      (!value.includes("*") &&
        !value.includes("[") &&
        !value.includes("]") &&
        !value.includes("?")) ||
      "The {_field_} must not contain a wildcard character (*, [, ], ?)"
    );
  },
});
extend("max", {
  ...max,
  message: "The {_field_} may not be greater than {length} characters",
});
extend("min", {
  ...min,
  message: "The {_field_} must be greater than {length} characters",
});
extend("verify_password", {
  message: `Passwords must contain at least one of each of the following: uppercase letter, lowercase letter, number, special character`,
  validate: (value) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()_=+,<.>/?|{}-])(?=.{8,})"
    );
    return strongRegex.test(value);
  },
});
extend("serial_number", {
  message:
    "{_field_} may only contain alphanumeric characters, hyphens, and periods",
  validate: (value: string) => {
    return value.match(/^[a-zA-Z0-9-.]+$/) !== null;
  },
});

extend("ip", {
  message: "Invalid IP Address",
  // https://stackoverflow.com/a/36760050
  validate: (value: string) => {
    return (
      value.match(
        // eslint-disable-next-line no-useless-escape
        /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/
      ) !== null
    );
  },
});

import { State } from "../state";
import { MapState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { GISLayerBase, MapboxLayersFixed } from "@/interfaces/gis_layer";

export const getters = {
  mapZoom: (state: MapState): number => state.zoom,
  center: (state: MapState): { lat: number; lng: number } => state.center,
  gisLayers: (state: MapState): GISLayerBase[] => state.GISLayers,
  mapboxGeoJSONLayers: (state: MapState): Partial<MapboxLayersFixed>[] => {
    return state.GISLayers.map((layer): Partial<MapboxLayersFixed> => {
      const type = ["circle", "line", "fill", "symbol", "raster"].includes(
        layer.layer_type
      )
        ? layer.layer_type
        : "circle";
      return {
        sourcetype: "geojson",
        source: layer.geojson,
        type: type as "circle" | "line" | "fill" | "symbol" | "raster",
        below: "traces",
        color: layer.color,
        circle: { radius: layer.layer_type === "point" ? 4 : 0 },
      };
    });
  },
};

const { read } = getStoreAccessors<MapState, State>("");

export const readMapZoom = read(getters.mapZoom);
export const readMapCenter = read(getters.center);
export const readGISLayers = read(getters.gisLayers);
export const readMapBoxGeoJSONLayers = read(getters.mapboxGeoJSONLayers);

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import RouterComponent from "@/components/RouterComponent.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "start" */ "../views/main/Start.vue"),
    children: [
      {
        path: "login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      },
      {
        path: "recover-password",
        component: () =>
          import(
            /* webpackChunkName: "recover-password" */ "../views/PasswordRecovery.vue"
          ),
      },
      {
        path: "reset-password",
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
          ),
      },
      {
        path: "main",
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/main/Main.vue"),
        children: [
          {
            path: "state",
            component: () =>
              import(
                /* webpackChunkName: "state-dashboard" */ "../views/main/State.vue"
              ),
          },
          {
            path: "notification_preferences",
            name: "main-notification-preferences",
            component: () =>
              import(
                /* webpackChunkName: "notifications-preferences-dashboard" */ "../views/main/NotificationPreferences.vue"
              ),
          },
          {
            path: "activities/all",
            name: "main-admin-activities-all",
            component: () =>
              import("../views/main/admin/activity/AllActivities.vue"),
          },
          {
            path: "activities/:id",
            name: "main-admin-activities-edit",
            component: () =>
              import(
                /* webpackChunkName: "main-admin-users-edit" */ "../views/main/admin/activity/ActivityDetailed.vue"
              ),
          },
          {
            path: "status",
            name: "main-status",
            component: () =>
              import(
                /* webpackChunkName: "status-dashboard" */ "../views/main/Status.vue"
              ),
          },
          {
            path: "user_manual",
            name: "user-manual",
            component: () =>
              import(
                /* webpackChunkName: "status-dashboard" */ "../views/main/UserManual.vue"
              ),
          },
          {
            path: "profile",
            component: RouterComponent,
            redirect: "profile/view",
            children: [
              {
                path: "view",
                component: () =>
                  import(
                    /* webpackChunkName: "main-profile" */ "../views/main/profile/UserProfile.vue"
                  ),
              },
              {
                path: "edit",
                component: () =>
                  import(
                    /* webpackChunkName: "main-profile-edit" */ "../views/main/profile/UserProfileEdit.vue"
                  ),
              },
              {
                path: "password",
                component: () =>
                  import(
                    /* webpackChunkName: "main-profile-password" */ "../views/main/profile/UserProfileEditPassword.vue"
                  ),
              },
            ],
          },
          {
            path: "admin",
            component: () =>
              import(
                /* webpackChunkName: "main-admin" */ "../views/main/admin/Admin.vue"
              ),
            redirect: "admin/users/all",
            children: [
              {
                path: "users",
                redirect: "users/all",
              },
              {
                path: "users/all",
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users" */ "../views/main/admin/users/AdminUsers.vue"
                  ),
              },
              {
                path: "users/edit/:id",
                name: "main-admin-users-edit",
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users-edit" */ "../views/main/admin/users/EditUser.vue"
                  ),
              },
              {
                path: "users/create",
                name: "main-admin-users-create",
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users-create" */ "../views/main/admin/users/CreateUser.vue"
                  ),
              },
              {
                path: "sections",
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users" */ "../views/main/admin/Sections.vue"
                  ),
              },
              {
                path: "site-config",
                name: "main-admin-siteconfig",
                component: () =>
                  import(
                    /* webpackChunkName: "site-config-dashboard" */ "../views/main/admin/SiteConfig.vue"
                  ),
              },
              {
                path: "raw-history",
                name: "main-admin-raw-history",
                component: () =>
                  import(
                    /* webpackChunkName: "raw-history-dashboard" */ "../views/main/admin/analysis/RawHistory.vue"
                  ),
              },
              {
                path: "section-thresholds",
                name: "main-admin-section-thresholds",
                component: () =>
                  import(
                    /* webpackChunkName: "raw-history-dashboard" */ "../views/main/admin/configuration/SectionThresholds.vue"
                  ),
              },
              {
                path: "model-configuration",
                name: "main-admin-model-configuration",
                component: () =>
                  import(
                    /* webpackChunkName: "model-configuration" */ "../views/main/admin/configuration/ModelConfiguration.vue"
                  ),
              },
              {
                path: "labels",
                name: "main-admin-labels",
                component: () =>
                  import(
                    /* webpackChunkName: "labels" */ "../views/main/admin/configuration/Labels.vue"
                  ),
              },
              {
                path: "gis-layer-configuration",
                name: "gis-layer-configuration",
                component: () =>
                  import(
                    /* webpackChunkName: "gis-layer-configuration" */ "../views/main/admin/configuration/GISLayer.vue"
                  ),
              },
              {
                path: "capture-rules",
                name: "capture-rules",
                component: () =>
                  import(
                    /* webpackChunkName: "capture-rules" */ "../views/main/admin/configuration/CaptureRules.vue"
                  ),
              },
              {
                path: "summary-plots",
                name: "summary-plots",
                component: () =>
                  import(
                    /* webpackChunkName: "summary-plots" */ "../views/main/admin/configuration/SummaryImageConfig.vue"
                  ),
              },
              {
                path: "devices",
                name: "main-admin-devices",
                component: () =>
                  import(
                    /* webpackChunkName: "devices-dashboard" */ "../views/main/admin/configuration/Devices.vue"
                  ),
              },
              {
                path: "assets",
                name: "main-admin-assets",
                component: () =>
                  import(
                    /* webpackChunkName: "assets-dashboard" */ "../views/main/admin/configuration/Assets.vue"
                  ),
              },
              {
                path: "edge-jobs",
                name: "main-admin-edge-jobs",
                component: () =>
                  import(
                    /* webpackChunkName: "edge-jobs" */ "../views/main/admin/EdgeJobs.vue"
                  ),
              },
              {
                path: "activity-analysis",
                name: "main-admin-activity-analysis",
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-activity-analysis" */ "../views/main/admin/analysis/SingleActivityAnalysis.vue"
                  ),
              },
              {
                path: "reports",
                name: "main-admin-reports",
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-conveyor-reports" */ "../views/main/admin/analysis/ConveyorReports.vue"
                  ),
              },
              {
                path: "maintenance",
                component: RouterComponent,
                redirect: "maintenance/services",
                children: [
                  {
                    path: "services",
                    name: "main-admin-maintenance-services",
                    component: () =>
                      import(
                        /* webpackChunkName: "main-admin-maintenance-services" */ "../views/main/admin/maintenance/Services.vue"
                      ),
                  },
                  {
                    path: "certificates",
                    name: "main-admin-maintenance-certificates",
                    component: () =>
                      import(
                        /* webpackChunkName: "main-admin-maintenance-services" */ "../views/main/admin/maintenance/Certificates.vue"
                      ),
                  },
                  {
                    path: "base_maps",
                    name: "main-admin-maintenance-base-maps",
                    component: () =>
                      import(
                        /* webpackChunkName: "main-admin-maintenance-base-maps" */ "../views/main/admin/maintenance/BaseMaps.vue"
                      ),
                  },
                  {
                    path: "modbus",
                    name: "main-admin-maintenance-modbus",
                    component: () =>
                      import(
                        /* webpackChunkName: "main-admin-maintenance-modbus" */ "../views/main/admin/maintenance/Modbus.vue"
                      ),
                  },
                  {
                    path: "recovery",
                    name: "main-admin-maintenance-recovery",
                    component: () =>
                      import(
                        /* webpackChunkName: "main-admin-maintenance-recovery" */ "../views/main/admin/maintenance/Recovery.vue"
                      ),
                  },
                  {
                    path: "scheduled",
                    name: "main-admin-maintenance-scheduled",
                    component: () =>
                      import(
                        /* webpackChunkName: "main-admin-maintenance-scheduled" */ "../views/main/admin/maintenance/Scheduled.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/*",
    redirect: "/",
  },
];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
